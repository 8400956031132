// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appUrl: 'https://maintenance.qa.zonarsystems.net',
  // still needs our api url
  apiBase: {
    url: 'https://api-qa.zonarsystems.net/gtcx-maintenance-api/v1'
  },
  coreEntityApiBase: {
    url: 'https://api-qa.zonarsystems.net'
  },
  userPreferenceApiBase: {
    url: 'https://api-qa.zonarsystems.net/alert/v1'
  },
  authEnv: 'qa',
  name: 'qa-na',
  region: 'NA',
  auth: {
    clientID: '10nuuEicC2y9L7mh0LuWj5uj1Bvi0C11',
    domain: 'qa-login.zonarsystems.net',
    audience: 'http://apiqa.zonarsystems.net/',
    applicationId: '3f4ce284-c996-4af3-9f30-4d6223187897',
    defaultZonarRole: '7795bc71-2d33-4e18-aa93-fd5833ae9ecf',
    useRefreshTokens: true
  },

  datadog: {
    applicationId: 'c5b43798-865b-47a3-b73f-47dfdd8281d7',
    clientToken: 'pub1e068f1b0a0555200ba6a33e41abf98b',
    site: 'us5.datadoghq.com',
    tagEnvironment: 'qa'
  },

  i18n: {
    supportedLanguages: ['en-CA', 'en-US', 'de-DE', 'en-GB', 'fr-FR', 'es-ES'],
    defaultLanguage: 'en-US'
  },
  production: false,
  gtcxUrl: {
    activityFeed: 'https://activity-feed-ui.qa.zonarsystems.net/',
    maps: 'https://maps.qa.zonarsystems.net/'
  },
  lagacyGtc: {
    diagnostics:
      'https://gtc4qa-cloud.zonarsystems.net/legacy?url=%2Fdiagnostics%2Fjbus',
    gpsUnitHealth:
      'https://gtc4qa-cloud.zonarsystems.net/legacy?url=%2Freports%2Fgps-unit-health',
    faultIq: 'https://tripvision.noregon.com'
  },
  pendo: {
    url: 'https://cdn.pendo.io/agent/static/'
  },
  devCycle: {
    clientId: 'dvc_client_e2d70ced_cf95_4ac6_b826_3c38580725fd_b0a2168',
    assetSummaryFeature: 'asset-summary'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
